exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-landing-page-index-tsx": () => import("./../../../src/templates/ArticleLandingPage/index.tsx" /* webpackChunkName: "component---src-templates-article-landing-page-index-tsx" */),
  "component---src-templates-articles-index-tsx": () => import("./../../../src/templates/Articles/index.tsx" /* webpackChunkName: "component---src-templates-articles-index-tsx" */),
  "component---src-templates-contact-landing-page-index-tsx": () => import("./../../../src/templates/ContactLandingPage/index.tsx" /* webpackChunkName: "component---src-templates-contact-landing-page-index-tsx" */),
  "component---src-templates-home-index-tsx": () => import("./../../../src/templates/Home/index.tsx" /* webpackChunkName: "component---src-templates-home-index-tsx" */),
  "component---src-templates-login-page-index-tsx": () => import("./../../../src/templates/LoginPage/index.tsx" /* webpackChunkName: "component---src-templates-login-page-index-tsx" */),
  "component---src-templates-main-landing-page-index-tsx": () => import("./../../../src/templates/MainLandingPage/index.tsx" /* webpackChunkName: "component---src-templates-main-landing-page-index-tsx" */),
  "component---src-templates-sitemap-page-index-tsx": () => import("./../../../src/templates/SitemapPage/index.tsx" /* webpackChunkName: "component---src-templates-sitemap-page-index-tsx" */)
}

